<template>
	<div>
		<h1 class="display-3">Version 1.5.1 - May 2022</h1>
		<div class="notes ">
			<hr />
			<h2><strong>Network Treeview Widget</strong></h2>
			<div class="text-center mb-15">
				<img src="/media/releasenotes/2022/05/NetworkTreeRemote.gif" width="600" />
			</div>
			<p>
				Back by popular demand is the Network Treeview Widget. For admin users this breaks down every iDirect modem and linecard by NMS, then Network,
				Inroutegroup and finally remote. It looks a lot like the sidebar in iBuilder which is on purpose (we even use the same icons).
				<br />
				It can also be useful for useradmin who have multiple child accounts. In that case the top level will be the network (not NMS) and they won’t
				see linecards with the remotes.
			</p>
			<div class="text-center mb-15">
				<img src="/media/releasenotes/2022/05/DashboardLayout.gif" width="600" />
			</div>
			<p>
				If it isn’t already added to your dashboard and you would like it to be, you can add it by visiting the Admin Edit Account page, clicking the
				Dashboard Layout tab and adding it using the drag and drop grid.
			</p>
			<h2>
				<strong>Network Page</strong>
			</h2>
			<div class="text-center mb-15">
				<img src="/media/releasenotes/2022/05/NetworkTreeNetwork.gif" width="600" />
			</div>
			<p>
				The Network Treeview also has one other feature for admin users: when opening an NMS you can click on the network name to go to the Network
				page. This page is similar to what you would see in the NOC Portal but now is built into the VSAT Portal to enable quicker views of a network.
			</p>
			<h2>
				<strong>Alert Banner</strong>
			</h2>
			<div class="text-center mb-15">
				<img src="/media/releasenotes/2022/05/AlertBanner.gif" width="600" />
			</div>
			<p>
				Under the ‘NOC Alerts’ page there is a tab called Alert Banner which allows an admin user to show a message cross the top of the portal to
				alert users to ongoing or potential service issues. To create an alert, click the Add New Alert button, enter a message to show in the banner,
				select an expiration time (the banner will automatically stop showing after this time) and then select the networks that you would like to
				target.
			</p>
			<h3>Dashboard</h3>
			<p>
				On the dashboard page alerts are visible based on the user account.
				<br />
				Admin users will see all alerts currently active
				<br />

				Other users will see alerts that are active for networks that they have at least one device configured for.
			</p>
			<h3>Sites</h3>
			<p>
				On the site page alerts are visible based on the networks configured for that site. If the site is configured for a network that has an active
				alert the banner will show regardless of the user currently viewing the site.
			</p>
			<h2><strong>Additional Improvements</strong></h2>
			<h4><strong>Menu Bar</strong></h4>
			<p>
				We’ve updated the look and feel of some of the menu bar components to make them easier to use. The ‘Viewing As’ notification has been improved
				to fix some layout issues, to make it easier to see, and to match the design language of the portal.
			</p>
			<div class="d-flex justify-content-center mb-15">
				<div class="text-center mr-2">
					<img src="/media/releasenotes/2022/05/viewing-as.png" width="300" />
				</div>
				<div class="text-center">
					<img src="/media/releasenotes/2022/05/new-viewing-as.png" width="300" />
				</div>
			</div>
			<p>
				Additionally the VOIP Calls and Alert Notifications now show the numbers of ongoing events without you having to open them to see. The will
				also refresh when you open them so you will always see the latest events.
			</p>
			<div class="text-center mb-15">
				<img src="/media/releasenotes/2022/05/topbar-icons.png" width="500" />
			</div>
			<h4>
				<strong>Admin Edit Account</strong>
			</h4>
			<p>
				You also may have noticed a few updates in the admin edit account page. We’ve fixed some bugs and improved the look and feel for some of the
				sections.
			</p>
			<p>
				The dashboard layout has been updated to be easier to see and have bigger buttons for things like the widget properties so they are not missed
				as easily.
			</p>
			<div class="text-center">
				<img src="/media/releasenotes/2022/05/change-image.png" width="500" />
			</div>
			<p>
				The Change Image tab has been updated to fix a bug that made the preview inaccurate for what the final image would look like.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'latemay2022',
	data() {
		return {};
	},
};
</script>

<style scoped lang="scss">
h2 {
	margin: 45px 0;
	border-bottom: 1px solid rgba(99, 99, 99, 0.3);
}
h4 {
	margin: 25px 0;
}
.notes {
	max-width: 700px;

	p {
		font-size: 14px;
		margin: 15px 0;
		line-height: 24px;
	}
}
</style>
